import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import { Link } from "gatsby"

const Project = ({
  desc: { desc },
  title,
  projektStart,
  fertig,
  projektBild,
  url,
  skills: { tags },
}) => {
  return (
   
    <div class="flex flex-wrap items-center -mx-4 mb-16">
        <div class="w-full lg:w-2/5 px-4">
          <div class="text-left">
            <span class="inline-block mb-3 text-xs px-2 py-1 bg-blue-50 rounded uppercase text-blue-400 font-semibold">{title}</span>
            <h2 class="mb-4 text-3xl font-semibold font-heading">Werkzeuge: {tags}</h2>
            <h3 class="mb-6 text-xl text-gray-500">Start des Projekts: {projektStart}</h3>
            <h3 class="mb-6 text-xl text-gray-500">Fertigstellung: {fertig}</h3>
            <p class="text-xl text-gray-500">{desc}</p>
          </div>
        </div>
        <div class="order-first lg:order-last w-full lg:w-3/5 px-4 mb-8 lg:mb-0">
          <div class="h-80 w-80">
            <GatsbyImage
          image={getImage(projektBild)}
          className="h-80 w-80 w-full h-full object-cover rounded-lg"
          alt={title}
        />
          </div>
        </div>
      </div>
    
  )
}

export default Project
