import React from "react"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"
import Projekte from "../assets/images/hero.png"
const Projects = ({ projects, title }) => {
  return (
    <section class="py-20">
    <div class="container px-4 mx-auto">
      <div class="max-w-2/5 mb-20">
        
        <h2 class="mt-12 mb-10 text-4xl font-semibold font-heading">Wir stellen Ihnen eine Auswahl unserer Projekte vor.</h2>
        <p class="text-xl text-gray-500">Es kommen jeden Monat neue Projekte hinzu, die wir aber nicht alle hier vorstellen. Wir stellen besonders die Projekte vor, die ein gutes Beispiel für unsere Kunden sind</p>
      </div>

    
    {projects.map((project, index) => {
            return <Project key={project.id} index={index} {...project} />
          })}
          
          </div>
  </section>
  )
}

export default Projects
