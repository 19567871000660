import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import "../assets/css/main.css"
const ProjectsPage = ({
  data: {
    allContentfulProjekt: { nodes: projects },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo title="Webdesign Krefeld" />
        <main>
          <section className="projects-page">
            <Projects title="Projekte" projects={projects} />
          </section>
        </main>
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    allContentfulProjekt(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        desc {
          desc
        }
        title
        projektBild {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
        projektStart(formatString: "DD-MM-YYYY")
        fertig(formatString: "DD-MM-YYYY")
        url
        skills {
          tags
        }
      }
    }
  }
`
export default ProjectsPage
